import { Spin } from "antd";
import { isEmpty } from "lodash";
import get from "lodash.get";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { formatNumber } from "../../../utilities/common-function";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// markup

const DepositDetails = (props) => {
  const accessKey = get(props.user, "accessKey");
  // const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  // const [loading, setLoading] = useState(false);
  const [withdraw, setWithdraw] = useState({});

  function getData() {
    if (get(props, "product.withdrawalActiveId")) {
      feathers("withdraw-transactions", accessKey)
        .find({ _id: get(props, "product.withdrawalActiveId") })
        .then((res) => {
          //console.log(res);
          let data = get(res, "data[0]");
          setWithdraw(data || {});
        })
        .catch((err) => {
          // message.error("Record Not Found.");
        });
    }
  }

  useEffect(() => {
    getData();
  }, [props.product]);

  const _renderType = (type) => {
    switch (type) {
      case 2:
        return t("withdrawApproved", sourceKey.asset);
      case 1:
        return t("withdrawRejected", sourceKey.asset);
      case 0:
        return t("withdrawUnderReview", sourceKey.asset);
      default:
        break;
    }
  };

  let images = get(withdraw, "images");

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={false}>
          <NavHeader
            suffix={
              <div className="justify-end flex items-center">
                {t("withdrawalDetails", sourceKey.asset)}
              </div>
            }
            showBack={true}
          >
            <div className="p-4 mx-3">
              <span className="font-semibold text-2xl text-blue-400">
                {formatNumber(get(withdraw, "amount"), null, true, 8, true)}{" "}
                USDT
              </span>

              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 类型 <br /> Coin Type */}
                  {t("coinType", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {get(withdraw, "txid") || "USDT"}
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {t("withdrawalAddress", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {get(withdraw, "withdrawalAddress")}
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 提币数量 <br /> Withdrawal Amount */}
                  {t("withdrawAmount", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {get(withdraw, "amount")} USDT
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 状态 <br /> Status */}
                  {t("status", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {_renderType(get(withdraw, "status"))}
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 手续费 <br /> Service Charge */}
                  {t("serviceCharge", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {formatNumber(
                    get(withdraw, "serviceCharge"),
                    null,
                    true,
                    8,
                    true
                  )}{" "}
                  USDT
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 區塊鏈交易ID <br /> Withdrawal Transaction ID */}
                  {t("withdrawId", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {get(withdraw, "transactionId") || "-"}
                </span>
              </div>

              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 时间 <br />
                  Date */}
                  {t("date", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {get(withdraw, "transactionDate")
                    ? moment(get(withdraw, "transactionDate")).format(
                        "DD/MM/YYYY hh:mm:ss A"
                      )
                    : ""}
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 备注 <br /> */}
                  {/* Remarks */}
                  {t("remark", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {get(withdraw, "remark") || "-"}
                </span>
              </div>
              <div className="flex justify-between my-3 items-center border-b py-2">
                <span className="text-gray-500 inline-block w-1/3 mr-3">
                  {/* 照片 <br /> Image */}
                  {t("image", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 text-right">
                  {!isEmpty(images)
                    ? images?.map((i) => {
                        if (isEmpty(get(i, "url"))) {
                          return false;
                        } else {
                          return (
                            <img
                              src={get(i, "url")}
                              style={{
                                width: 550,
                                height: 400,
                              }}
                              className="img-cover"
                            />
                          );
                        }
                      })
                    : "-"}
                </span>
              </div>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  product: state.product,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(DepositDetails);
