import * as React from "react"
import SharedHeader from "../components/general/components/SharedHeader"
import WithdrawalDetailsPage from "../components/robot/pages/WithdrawalDetailsPage"


// markup
const WithdrawalDetails = () => {
    return (
        <React.Fragment>
            <SharedHeader />
            <WithdrawalDetailsPage/>
        </React.Fragment>
    )
}

export default WithdrawalDetails
